<template>
  <div v-if="data" class="projects-slide">
    <div class="projects-slide__img">
      <ImgComponent :img="data.img" />
    </div>
    <div class="projects-slide__content">
      <h3 v-if="data.title" class="projects-slide__title">
        {{ data.title }}
      </h3>
      <div class="projects-slide__program">
        <div v-if="data.target" class="projects-slide__program-text">
          <div class="projects-slide__program-line" />
          <span class="projects-slide__program-title"> Цель программы: {{ data.target }} </span>
        </div>
        <div v-if="data.result" class="projects-slide__program-text">
          <div class="projects-slide__program-line" />
          <span class="projects-slide__program-title"> Результат: {{ data.result }} </span>
        </div>
      </div>
      <router-link :to="{ name: 'project', params: { id: data.id } }" class="btn projects-slide__btn">
        <span>Подробнее</span>
        <IconComponent name="arrow-right" />
      </router-link>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "MainSlideComponent",
  components: { ImgComponent, IconComponent },
  props: {
    data: Object,
  },
};
</script>

<style lang="stylus">
.projects-slide {
  height 100%
  background var(--main)
  padding-top 12px
  display flex
  gap 50px
  width 100%
  +below(900px) {
    flex-direction column
    gap 20px
  }

  &__img {
    max-width: 620px;
    height: 540px;
    width 100%
    //transform translate(12px, -12px)
    //box-shadow: -12px 12px 0px 0px var(--main-light)
    +below(900px) {
      max-width 100%
    }
    +below(700px) {
      height 221px
    }

    img {
      width 100%
      height 100%
    }
  }
//
  &__content {
    //padding-top 95px
    width 100%
    display flex
    flex-direction column
    align-items flex-start
    gap 20px
  }

  &__title {
    font-weight: 900;
    font-size: 1.75rem;
    line-height: 42px;
    color var(--main-light)
    text-align start
    +below(700px) {
      font-size 1.125rem
      line-height 28px
    }
  }

  //&__description {
  //  padding-left 100px
  //  text-align start
  //  font-weight 500
  //  font-size 1rem
  //  line-height 26px
  //  color var(--main-light)
  //}

  &__program {
    display flex
    flex-direction column
    grid-gap 20px
  }

  &__program-text {
    display flex
    gap 15px
  }

  &__program-line {
    background var(--main-light)
    min-width 51px
    max-width 51px
    height 1px
    margin-top 13px
  }

  &__program-title {
    color var(--white)
    +below(700px) {
      font-size .875rem
      line-height 22px
    }
  }

  &__btn {
    display flex
    align-items center
    gap 10px

    span {
      font-size .875rem
      line-height 22px
      color var(--white)
    }

    .icon svg {
      width 16px
      height 16px

      path {
        fill var(--white)
      }
    }
  }

}
</style>
