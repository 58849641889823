<template>
  <a :href="item.link" target="_blank" class="useful-slide">
    <ImgComponent :img="item.img" class="useful-slide__image" />
  </a>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "UsefulSlide",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ImgComponent,
  },
};
</script>

<style lang="stylus">
.useful-slide {
  display flex
  justify-content center
  align-items center
  max-width 290px
  max-height 138px

  &__image {
    width 100%
    height 100%
    max-width 290px
    max-height 138px
  }
}
</style>
