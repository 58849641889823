<template>
  <section v-if="links && links.length" class="useful-slider">
    <BaseArticle class="container-padding" title="Полезные ссылки" />
    <SliderComponent
      v-if="links && links.length"
      class="home-page__useful-slider"
      :items="links"
      v-slot="{ item }"
      :slider-options="mainSliderOptions"
    >
      <UsefulSide v-if="item" :item="item" />
    </SliderComponent>
  </section>
</template>

<script>
import BaseArticle from "components/UI/BaseArticle.vue";
import SliderComponent from "components/SliderComponent.vue";
import UsefulSide from "./UsefulSide.vue";

export default {
  name: "UsefulSlider",
  data() {
    return {
      mainSliderOptions: {
        slidesPerView: 4,
        spaceBetween: 40,
        grid: {
          rows: 2,
        },
        breakpoints: {
          0: {
            slidesPerView: 2.1,
            spaceBetween: 15,
            grid: {
              rows: 1,
            },
          },
          700: {
            slidesPerView: 3.1,
            spaceBetween: 15,
            grid: {
              rows: 1,
            },
          },
          900: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 4,
          },
        },
      },
    };
  },
  computed: {
    links() {
      return this.$store.state.home.links;
    },
  },
  components: {
    SliderComponent,
    BaseArticle,
    UsefulSide,
  },
};
</script>

<style lang="stylus">
.useful-slider {
  display flex
  flex-direction column
  gap 40px
  max-height 414px

  .aside {
    +below(900px) {
      display none
    }
  }

  .home-page__useful-slider {
    +below(900px) {
      margin-left 15px
    }
  }
}
</style>
