<template>
  <section v-if="projects && projects.length" class="projects-slider">
    <BaseArticle title="Спецпроекты" :route="{ name: 'projects' }" white class="container-padding" />
    <SliderComponent
      class="home-page__projects-slider"
      :items="projects"
      v-slot="{ item }"
      :slider-options="projectsSliderOptions"
    >
      <ProjectsSlideComponent :data="item" />
    </SliderComponent>
  </section>
</template>

<script>
import BaseArticle from "components/UI/BaseArticle.vue";
import ProjectsSlideComponent from "./ProjectsSlideComponent.vue";
import SliderComponent from "components/SliderComponent.vue";

export default {
  name: "MainSlider",
  components: {
    SliderComponent,
    BaseArticle,
    ProjectsSlideComponent,
  },
  data() {
    return {
      projectsSliderOptions: {
        slidesPerView: 1,
        spaceBetween: 15,
        breakpoints: {
          0: {
            slidesPerView: 1.3,
          },
          700: {
            slidesPerView: 1,
          },
          // 400: {
          //   slidesPerView: 3,
          // },
          // 480: {
          //   slidesPerView: 4,
          // },
        },
      },
    };
  },
  computed: {
    projects() {
      return this.$store.state.home.projects_paginate?.data;
    },
  },
};
</script>

<style lang="stylus">
.home-page__projects-slider {
  +below(700px) {
    padding 0 0 0 30px
  }

  .swiper-slide {
    background: var(--main);
  }

  .aside {
    background var(--main)
    +below(1100px) {
      display none
    }

    .arrows__info {
      color var(--white)
    }
  }
}

.projects-slider {
  margin-top: -50px;
  padding 40px 0 50px 0
  display flex
  flex-direction column
  gap 40px
  background var(--main)
  +below(700px) {
    padding 30px 0
    gap 15px
  }

  .arrows__button {
    .icon svg path {
      fill var(--white)
    }
  }

  //.article {
  //  +below(1100px) {
  //    display none
  //  }
  //}
}
</style>
