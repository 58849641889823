<template>
  <div
    class="aside"
    :class="{ 'aside--link-active': link, 'aside--white': white, 'aside--transparent': transparent }"
  >
    <router-link v-if="link" :to="link" class="aside__link">
      <slot />
    </router-link>
    <div v-else class="aside__arrows">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseAside",
  props: {
    link: Object,
    white: Boolean,
    transparent: Boolean,
  },
};
</script>

<style lang="stylus">
.aside {
  width 100%
  background var(--gray)
  max-width 80px
  min-height 200px
  display flex
  justify-content center
  align-items center
  +below(700px) {
    max-height 50px
    min-height 50px
    max-width 100%
    order 1
  }

  &--white {
    background var(--white)
  }

  &--transparent {
    background var(--transparent)
  }

  &--link-active {
    cursor pointer
    transition var(--transition)

    &:active {
      transform scale(0.97)
    }
  }

  &__link {
    transform rotate(-90deg)
    min-width 130px
    display flex
    justify-content center
    gap 10px
    align-items center
    +below(700px) {
      transform none
    }

    span {
      font-size .875rem
      line-height 22px
      color var(--main)
    }

    .icon {
      width 16px
      height 16px

      svg path {
        fill var(--main)
      }
    }
  }

  &__arrows {
    transform rotate(-90deg)
    display flex
  }
}
</style>
