<template>
  <section v-if="slider_news && slider_news.length" class="main-slider">
    <SliderComponent
      class="home-page__main-slider"
      :items="slider_news"
      v-slot="{ item }"
      :slider-options="mainSliderOptions"
    >
      <MainSlideComponent :item="item" />
    </SliderComponent>
  </section>
</template>

<script>
import SliderComponent from "components/SliderComponent.vue";
import MainSlideComponent from "./MainSlideComponent.vue";
export default {
  name: "MainSlider",
  components: {
    SliderComponent,
    MainSlideComponent,
  },
  data() {
    return {
      mainSliderOptions: {
        slidesPerView: 1,
      },
    };
  },
  computed: {
    slider_news() {
      return this.$store.state.home.slider_news?.data;
    },
  },
};
</script>

<style lang="stylus">
.home-page__main-slider {
  +below(700px) {
    padding 0 30px
  }

  & .aside {
    +below(700px) {
      display none
    }
  }
}
</style>
