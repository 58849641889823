<template>
  <section class="home-media">
    <div class="home-media__container container-padding">
      <BaseArticle :route="{ name: 'media' }" title="Медиа материалы" />
      <div class="home-media__tabs">
        <button
          v-for="(tab, i) in tabs"
          :key="tab.id"
          @click="activeTab = i"
          class="btn home-media__btn"
          :class="{ 'home-media__btn--active': activeTab === i }"
        >
          {{ tab.name }}
        </button>
      </div>
      <div class="home-media__content">
        <BaseAside :link="{ name: 'media' }" white>
          <span>Смотреть все</span>
          <IconComponent name="right-arrow" />
        </BaseAside>
        <template v-if="activeTab === 0">
          <div v-if="photo_news && photo_news.length" class="home-media__list">
            <CardComponent v-for="(item, i) in photo_news" :key="i" :item="item" />
          </div>
          <p v-else>В данный момент информация отсутствует</p>
        </template>
        <template v-if="activeTab === 1">
          <div class="home-media__list">
            <!--            <CardComponent v-for="item in 4" :key="item" media />-->
            <span class="home-media__soon">Скоро...</span>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import BaseAside from "components/UI/BaseAside.vue";
import BaseArticle from "components/UI/BaseArticle.vue";
import CardComponent from "components/CardComponent.vue";
import IconComponent from "components/IconComponent.vue";

export default {
  name: "NewsSection",
  data() {
    return {
      activeTab: 0,
      tabs: [
        {
          id: 1,
          name: "Фотогалереея",
        },
        {
          id: 2,
          name: "Видеогалереея",
        },
      ],
    };
  },
  computed: {
    photo_news() {
      return this.$store.state.home.photo_news?.data;
    },
    video_news() {
      return this.$store.state.home.video_news?.data;
    },
  },
  components: {
    BaseArticle,
    BaseAside,
    CardComponent,
    IconComponent,
  },
};
</script>

<style lang="stylus">
.home-media {

  & .article {
    +below(700px) {
      padding 0 30px 0 0
    }
  }

  & .aside {
    +below(700px) {
      max-width 95%
    }
  }

  &__container {
    display flex
    flex-direction column
    gap 40px
    +below(700px) {
      padding 0 0 0 30px
      gap 30px
    }
  }

  &__tabs {
    margin-left 120px
    +below(700px) {
      margin-left 0
    }
  }

  &__btn {
    padding 11px 30px
    font-size 1.125em
    font-weight 700
    //color var(--gray-dark)
    //border-bottom 2px solid var(--white)
    +below(700px) {
      padding 13px 10px
      font-size .875rem
      line-height 22px
    }
  }

  &__btn--active {
    color var(--main)
    border-bottom 2px solid var(--main)
  }

  &__content {
    display flex
    gap 40px
    +below(700px) {
      flex-direction column
      gap 15px
    }
  }

  &__list {
    width 100%
    display grid
    grid-template-columns repeat(4, 1fr)
    gap 40px
    +below(1250px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(1100px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(700px) {
      display flex
      overflow auto
      gap 15px
      padding-bottom 15px

      //&::-webkit-scrollbar {
      //  width 7px
      //  height 5px
      //}
    }
  }

  &__soon {
    color var(--main)
    font-size 2em
    font-weight 900
    font-family var(--font-second)
  }

  &__aside {
    cursor: pointer;
    width 100%
    background var(--gray)
    max-width 136px
    display flex
    justify-content center
    align-items center
    transition var(--transition)


    &:active {
      transform scale(0.97)
    }
  }

  &__aside-content {
    transform rotate(-90deg)
    display flex
    justify-content center
    gap 10px
    align-items center
    color var(--main)
    font-size 0.875em

    .icon {
      width 16px
      height 16px

      svg path {
        fill var(--main)
      }
    }
  }
}
</style>
