<template>
  <div class="slider" v-if="items && items.length" :class="{ 'slider--grid': isGridMode }">
    <div class="slider__items">
      <BaseAside transparent>
        <SliderArrowsComponent
          v-if="swiper && !hideArrows && canShowArrows"
          :current="activeIndex + 1"
          :isBeginning="swiper.isBeginning"
          :isEnd="swiper.isEnd"
          @left="swiper.slidePrev()"
          @right="swiper.slideNext()"
        />
      </BaseAside>
      <div class="swiper" ref="items">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, i) in items" :key="i">
            <slot :grid="isGridMode" :item="item"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SliderArrowsComponent from "components/SliderArrowsComponent.vue";
import BaseAside from "components/UI/BaseAside.vue";

export default {
  name: "SliderComponent",
  props: {
    title: String,
    buttonTitle: String,
    items: Array,
    hideArrows: Boolean,
    route: {
      type: Object,
      default() {
        return {
          name: "",
        };
      },
    },
    sliderOptions: {
      type: Object,
      default() {
        return {
          slidesPerView: "auto",
          spaceBetween: 24,
          pagination: false,
          clickable: false,
        };
      },
    },
  },
  data() {
    return {
      swiper: null,
    };
  },
  computed: {
    activeIndex() {
      if (this.swiper) {
        return this.swiper.realIndex;
      }
      return 0;
    },
    canShowArrows() {
      let slidesPerView = this.getCurrentBreakpoint().slidesPerView;
      if (typeof slidesPerView === "string") {
        return slidesPerView === "auto";
      }
      if (typeof slidesPerView === "number") {
        return this.items.length > slidesPerView;
      }
      return false;
    },
    isGridMode() {
      return this.sliderOptions?.grid;
    },
  },
  mounted() {
    require(["swiper/swiper-bundle.esm.browser.js"], ({ Swiper }) => {
      this.swiper = new Swiper(this.$refs.items, this.sliderOptions);
    });
  },
  methods: {
    getCurrentBreakpoint() {
      if (this.sliderOptions.breakpoints && typeof window !== "undefined") {
        let returnCandidate = this.sliderOptions;
        Object.keys(this.sliderOptions.breakpoints).forEach((key) => {
          if (window.innerWidth > parseInt(key)) {
            returnCandidate = this.sliderOptions.breakpoints[key];
          }
        });
        return returnCandidate;
      }
      return this.sliderOptions;
    },
  },
  components: {
    SliderArrowsComponent,
    BaseAside,
  },
};
</script>

<style lang="stylus">
@import "~swiper/swiper-bundle.min.css"

.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  width 100%
  //display flex
  //text-align: center;
  //font-size: 18px;
  //background: #fff;
  height: 100%
  //justify-content: center;
  //align-items: center;
}


.slider {
  display flex
  flex-direction: column;
  justify-content center
  align-items center
  padding 0 15px
  height: 100%;

  &--grid {
    .swiper-slide {
      height: calc((100% - 50px) / 2) !important;
    }
  }

  &__title {
    font-weight: normal;
    font-size: 1.5em;
    line-height: 32px;
    color: var(--body-color);
  }

  &__items {
    position relative
    display flex
    gap 40px
    max-width var(--main-width)
    width 100%
    height 100%
  }

  &__header {
    max-width var(--main-width)
    display flex
    align-items center
    width 100%
    margin-bottom 24px
    justify-content space-between

    +below(860px) {
      display grid
      grid-gap 16px
      justify-content stretch

      .btn {
        width 100%
        justify-content center
      }
    }
  }

  &__title {
    font-weight: 500;
    font-size: 1.5em;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: var(--dark);
    margin 0
  }
}
</style>
