<template>
  <section class="home-news">
    <div class="home-news__container container-padding">
      <BaseArticle title="Новости правительства" :route="{ name: 'news' }" />
      <!--      <div class="home-news__tabs">-->
      <!--        <button-->
      <!--          v-for="(tab, i) in tabs"-->
      <!--          :key="`${tab.id}_${i}`"-->
      <!--          @click="toggleTab(tab.id)"-->
      <!--          class="btn home-news__btn"-->
      <!--          :class="{ 'home-news__btn&#45;&#45;active': activeTab === tab.id }"-->
      <!--        >-->
      <!--          {{ tab.name }}-->
      <!--        </button>-->
      <!--      </div>-->
      <div class="home-news__content">
        <BaseAside :link="{ name: 'news' }" white>
          <span>Все новости</span>
          <IconComponent name="right-arrow" />
        </BaseAside>
        <!--        <div v-if="activeNews && activeNews.length" class="home-news__list">-->
        <!--          <CardComponent v-for="(item, i) in activeNews" :key="`${i}_${item.id}`" :item="item" />-->
        <!--        </div>-->
        <div v-if="department_news && department_news.length" class="home-news__list">
          <CardComponent v-for="(item, i) in department_news" :key="i" :item="item" />
        </div>
        <p v-else>В данный момент информация отсутствует</p>
      </div>
    </div>
  </section>
</template>

<script>
import BaseAside from "components/UI/BaseAside.vue";
import BaseArticle from "components/UI/BaseArticle.vue";
import CardComponent from "components/CardComponent.vue";
import IconComponent from "components/IconComponent.vue";

export default {
  name: "NewsSection",
  // data() {
  //   return {
  //     activeTab: 1,
  //     activeNews: [],
  //     tabs: [
  //       {
  //         id: 1,
  //         name: "Председатель",
  //       },
  //       {
  //         id: 2,
  //         name: "Правительство",
  //       },
  //       {
  //         id: 3,
  //         name: "Общие",
  //       },
  //     ],
  //   };
  // },
  // mounted() {
  //   this.activeNews = this.minister_news;
  // },
  computed: {
    // minister_news() {
    //   return this.$store.state.home.minister_news?.data;
    // },
    department_news() {
      return this.$store.state.home.department_news?.data;
    },
    // exception_news() {
    //   return this.$store.state.home.exception_news?.data;
    // },
  },
  // methods: {
  //   toggleTab(id) {
  //     switch (id) {
  //       case 1:
  //         this.activeTab = id;
  //         this.activeNews = this.minister_news;
  //         break;
  //       case 2:
  //         this.activeTab = id;
  //         this.activeNews = this.department_news;
  //         break;
  //
  //       case 3:
  //         this.activeTab = id;
  //         this.activeNews = this.exception_news;
  //         break;
  //     }
  //   },
  // },
  components: {
    BaseArticle,
    BaseAside,
    CardComponent,
    IconComponent,
  },
};
</script>

<style lang="stylus">
.home-news {

  & .article {
    +below(700px) {
      padding 0 30px 0 0
    }
  }

  & .aside {
    +below(700px) {
      max-width 95%
    }
  }

  &__container {
    display flex
    flex-direction column
    gap 40px
    +below(700px) {
      padding 0 0 0 30px
      gap 30px
    }
  }

  &__tabs {
    margin-left 120px
    +below(700px) {
      margin-left 0
    }
  }

  &__btn {
    padding 11px 30px
    font-size 1.125em
    font-weight 700
    //color var(--gray-dark)
    //border-bottom 2px solid var(--white)
    +below(700px) {
      padding 13px 10px
      font-size .875rem
      line-height 22px
    }
  }

  &__btn--active {
    color var(--main)
    border-bottom 2px solid var(--main)
  }

  &__content {
    display flex
    gap 40px
    +below(700px) {
      gap 15px
      flex-direction column
    }
  }

  &__list {
    width 100%
    display grid
    grid-template-columns repeat(4, 1fr)
    gap 40px
    +below(1200px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(900px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(700px) {
      display flex
      overflow auto
      gap 15px
      padding-bottom 15px
    }
  }

  &__aside {
    cursor: pointer;
    width 100%
    background var(--gray)
    max-width 136px
    display flex
    justify-content center
    align-items center
    transition var(--transition)


    &:active {
      transform scale(0.97)
    }
  }

  &__aside-content {
    transform rotate(-90deg)
    display flex
    justify-content center
    gap 10px
    align-items center
    color var(--main)
    font-size 0.875em

    .icon {
      width 16px
      height 16px

      svg path {
        fill var(--main)
      }
    }
  }
}
</style>
