<template>
  <div class="arrows" :class="{ 'arrows--white': white }">
    <button type="button" class="arrows__button arrows__button--left" @click="$emit('left')">
      <IconComponent name="left_arrow" />
    </button>
    <div class="arrows__info">
      <span>{{ current }}</span>
    </div>
    <button type="button" class="arrows__button arrows__button--right" @click="$emit('right')">
      <IconComponent name="left_arrow" />
    </button>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "SliderArrowsComponent",
  props: {
    current: Number,
    white: Boolean,
  },
  mounted() {},
  data() {
    return {};
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.arrows {
  display flex
  align-items center
  gap 35px

  &--white {
    & ^[0]__button {
      background var(--white)
      border-color var(--gray-dark)

      .icon svg path {
        fill var(--gray-dark)
      }

      &--right .icon svg path {
        fill var(--main)
      }
      &--left .icon svg path {
        fill var(--main)
      }

    }

    & ^[0]__info {
      color var(--dark-light)
    }

    & ^[0]__line {
      background var(--dark)
    }
  }

  &__button {
    cursor pointer
    border: 1px solid var(--dark-light);
    border-radius: 100px;
    background transparent
    width 50px
    height 50px
    padding 0
    display inline-flex
    align-items center
    justify-content center
    position relative

    .icon {
      width 20px
      height 20px

      svg path {
        fill var(--white)
      }
    }
  }

  &__button--right {
    transform rotate(90deg)
    border none

    .icon svg path {
      fill var(--main)
    }
  }
  &__button--left {
    transform rotate(-90deg)
    border none

    .icon svg path {
      fill var(--main)
    }
  }

  &__info {
    transform rotate(90deg)
    font-weight: 700;
    font-size: 0.875em;
    line-height: 20px;
    min-width: 15px;
    height: 20px;
    color: var(--main);
    display flex
    justify-content center
    align-items center
    gap 5px
  }
}
</style>
