<template>
  <main class="home-page">
    <MainSlider />
    <NewsSection />
    <WidgerSlider />
    <HomeDocumentsSection />
    <ProjectsSlider />
    <!--    <MapSection />-->
    <MediaSection />
    <PopularSection />
    <UsefulSlider />
  </main>
</template>

<script>
import NewsSection from "./components/NewsSection.vue";
import MainSlider from "./components/MainSlider.vue";
import ProjectsSlider from "./components/ProjectsSlider.vue";
// import MapSection from "./components/MapSection.vue";
import MediaSection from "./components/MediaSection.vue";
import PopularSection from "./components/PopularSection.vue";
import UsefulSlider from "./components/UsefulSlider.vue";
import HomeDocumentsSection from "./components/HomeDocumentSection.vue";
import HOME_PAGE from "gql/pages/HomePage.graphql";
import WidgerSlider from "components/WidgetSlider.vue";

export default {
  name: "HomePage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: HOME_PAGE,
      })
      .then(({ data }) => {
        store.dispatch("home/save", data);
      })
      .catch(() => {});
  },
  components: {
    WidgerSlider,
    HomeDocumentsSection,
    NewsSection,
    MainSlider,
    ProjectsSlider,
    // MapSection,
    MediaSection,
    PopularSection,
    UsefulSlider,
  },
};
</script>

<style lang="stylus">
.home-page {
  padding 50px 0
  display flex
  flex-direction column
  gap 60px
  +below(700px) {
    gap 30px
    background var(--gray)
    padding 30px 0
  }
}
</style>
