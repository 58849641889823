<template>
  <div v-if="item" class="main-slide">
    <div class="main-slide__img">
      <ImgComponent :img="item.img" />
    </div>
    <div class="main-slide__content">
      <span v-if="item.published_at" class="main-slide__date">{{ item.published_at | humanDate }}</span>
      <h1 v-if="item.title" class="main-slide__title">
        {{ item.title }}
      </h1>
      <span v-if="item.short_description" class="main-slide__description">
        {{ item.short_description }}
      </span>
      <router-link :to="{ name: 'newsItem', params: { id: item.id } }" class="btn main-slide__btn">
        <span>Подробнее</span>
        <IconComponent name="arrow-right-long" />
      </router-link>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "MainSlideComponent",
  components: { ImgComponent, IconComponent },
  props: {
    item: Object,
  },
};
</script>

<style lang="stylus">
.main-slide {
  display flex
  align-items center
  gap 40px
  +below(700px) {
    flex-direction column
    gap 15px
  }

  &__img {
    max-width 620px
    height 500px
    width 100%
    +below(700px) {
      height 280px
    }

    img {
      width 100%
      height 100%
    }
  }

  &__content {
    width 100%
    display flex
    flex-direction column
    align-items flex-start
    gap 20px
  }

  &__date {
    font-size .875rem
    line-height 22px
    color var(--main_o5)
    +below(700px) {
      font-size .75rem
      line-height normal
    }
  }

  &__title {
    font-size 2.375rem
    line-height 51px
    text-transform none
    +below(700px) {
      font-size 1.5rem
      line-height normal
    }
  }

  &__description {
    font-size .875rem
    line-height 22px
  }

  &__btn {
    display flex
    align-items center

    span {
      font-size .875rem
      line-height 22px
      color var(--main)
    }

    .icon {
      width 50px
      height 8px
    }
  }
}
</style>
